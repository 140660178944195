import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { BarLoader } from 'react-spinners';
import { toast } from 'react-toastify';
// import { MdClose } from 'react-icons/md';
import { ImExit } from "react-icons/im";
import {
  FormContainer,
  Form,
  FormGroup,
  Label,
  Input,
  // Select,
  Button,
  ContainerButton,
  ButtonC,
  Close,
  FormHeader
} from './styles';
import api from '../../../../../services/api';
import { getToken } from '../../../../../utils/utils';

class EmpresaRegistration extends Component {
  state = {
    name: '',
    razao: '',
    cnpj: '',
    insc_estadual: '',
    data_criacao: '',
    email: '',
    telefone: '',
    loading: false,
    id_empresa_registered: ''
  };

  async componentDidMount() {
    const { empresa } = this.props;
    if (empresa) {
      this.setState({
        id_empresa_registered: empresa.id,
        name: empresa.name,
        razao: empresa.razao,
        cnpj: empresa.cnpj,
        insc_estadual: empresa.insc_estadual,
        data_criacao: empresa.data_criacao,
        email: empresa.email,
        telefone: empresa.telefone,
      });
    }
    window.addEventListener('keydown', this.handleKeyDown);
  };


  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  handleKeyDown = (event) => {
    const { onClose } = this.props;
    if (event.key === 'Escape') {
      onClose();
    }
  };




  handleSubmit = async (e) => {
    e.preventDefault();
    const { name, razao, cnpj, insc_estadual, data_criacao, email, telefone, id_empresa_registered } = this.state;

    if (!id_empresa_registered) {
      const data = {
        name,
        razao,
        cnpj,
        insc_estadual,
        data_criacao,
        email,
        telefone,
      };
      const token = getToken();
      const config = {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
      await axios.post(`${api}?route=empresa-create`, data, config).then((response) => {
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }).catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          this.setState({ loading: false });
        } else {
          toast.warn('Falha de conexão!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          this.setState({ loading: false });
        }
      });
    } else {
      const data = {
        id: id_empresa_registered,
        name,
        razao,
        cnpj,
        insc_estadual,
        data_criacao,
        email,
        telefone,
      };
      const token = getToken();
      const config = {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
      await axios.post(`${api}?route=empresa-update`, data, config).then((response) => {
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }).catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          this.setState({ loading: false });
        } else {
          toast.warn('Falha de conexão!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          this.setState({ loading: false });
        }
      });
    }
  };
  render() {
    const { onClose } = this.props;
    const { id_empresa_registered, loading } = this.state;
    return (
      <FormContainer>
        <Form onSubmit={this.handleSubmit}>
          <FormHeader>
            <Close type='button' onClick={() => onClose()}>
              <span>Esc</span>
              <ImExit size={20} />
            </Close>
          </FormHeader>
          <FormGroup>
            <Label>Razao Social</Label>
            <Input
              type="text"
              name="razao"
              value={this.state.razao}
              onChange={(e) => this.setState({ razao: e.target.value })}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label>Nome Fantasia</Label>
            <Input
              type="text"
              name="name"
              value={this.state.name}
              onChange={(e) => this.setState({ name: e.target.value })}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label>CNPJ</Label>
            <Input
              type="text"
              name="cnpj"
              value={this.state.cnpj}
              onChange={(e) => this.setState({ cnpj: e.target.value })}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label>IE</Label>
            <Input
              type="text"
              name="IE"
              value={this.state.insc_estadual}
              onChange={(e) => this.setState({ insc_estadual: e.target.value })}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label>Data/Registro</Label>
            <Input
              type="date"
              name="data_criacao"
              value={this.state.data_criacao}
              onChange={(e) => this.setState({ data_criacao: e.target.value })}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label>Email</Label>
            <Input
              type="email"
              name="email"
              value={this.state.email}
              onChange={(e) => this.setState({ email: e.target.value })}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label>Telefone</Label>
            <Input
              type="text"
              name="telefone"
              value={this.state.telefone}
              onChange={(e) => this.setState({ telefone: e.target.value })}
              required
            />
          </FormGroup>
          <ContainerButton>
            <ButtonC type="button" onClick={() => onClose()}>Cancelar</ButtonC>
            <Button>
              {loading ? <BarLoader color='white' size={30} /> : (
                id_empresa_registered ? 'Atualizar' : 'Cadastrar'
              )}
            </Button>
          </ContainerButton>
        </Form>
      </FormContainer>
    );
  }
}

EmpresaRegistration.propTypes = {
  onClose: PropTypes.func.isRequired,
  empresa: PropTypes.func.isRequired
}

export default EmpresaRegistration;
