import React, { Component } from 'react';
import axios from 'axios';
import { BarLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import { FiEye, FiEyeOff } from "react-icons/fi";
import {
    FormContainer,
    Form,
    FormGroup,
    Label,
    Input,
    Select,
    Button,
    ContainerButton,
    Loading,
    // ButtonC
} from './styles';
import api from '../../../../services/api';
import { getToken } from '../../../../utils/utils';

class MinhaConta extends Component {
    state = {
        id: '',
        name: '',
        cpf: '',
        email: '',
        data_nascimento: '',
        password: '',
        password_confirm: '',
        fk_empresa_id: '',
        fk_filial_id: '',
        fk_cargo_id: '',
        empresas: [],
        cargos: [],
        filiais: [],
        passwordview: false,
        loadingData: false
    };

    async componentDidMount() {
        this.handleGetData();
        setTimeout(() => {
            this.setState({
                password: ''
            })
        }, 1000);
    };

    handleGetData = async () => {
        this.setState({ loadingData: true });
        const token = getToken();
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }
        await axios.get(`${api}?route=user-profile`, config).then((response) => {
            this.setState({
                id: response.data.id,
                name: response.data.name,
                cpf: response.data.cpf,
                email: response.data.email,
                data_nascimento: response.data.data_nascimento,
                celular: response.data.celular,
                fk_cargo_id: response.data.fk_cargo_id,
                fk_empresa_id: response.data.fk_empresa_id,
                fk_filial_id: response.data.fk_filial_id,
            })
        }).catch((err) => { });
        await axios.post(`${api}?route=filial-index`, {}, config).then((response) => {
            this.setState({
                filiais: response.data
            })
        }).catch((err) => { });
        await axios.post(`${api}?route=empresa-index`, {}, config).then((response) => {
            this.setState({
                empresas: response.data
            })
        }).catch((err) => { });
        await axios.get(`${api}?route=cargo-index`, config).then((response) => {
            this.setState({
                cargos: response.data
            })
        }).catch((err) => { });
        this.setState({ loadingData: false });
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        this.setState({ loading: true });
        const { id, name, cpf, email, celular, data_nascimento, fk_cargo_id, fk_empresa_id, fk_filial_id, password } = this.state;
        const data = {
            id,
            name,
            cpf,
            email,
            celular,
            data_nascimento,
            password,
            fk_cargo_id,
            fk_empresa_id,
            fk_filial_id
        };
        const token = getToken();
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }
        await axios.post(`${api}?route=user-update`, data, config).then((response) => {
            toast.success(response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }).catch((err) => {
            if (err.response) {
                toast.error(err.response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                this.setState({ loading: false });
            } else {
                toast.warn('Falha de conexão!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
                this.setState({ loading: false });
            }
        });
        this.setState({ loading: false });
    };

    render() {
        const { passwordview, loadingData } = this.state;
        return (
            <>

                {!loadingData && (
                    <FormContainer>
                        <Form onSubmit={this.handleSubmit}>
                            <FormGroup>
                                <Label>Nome</Label>
                                <Input
                                    type="text"
                                    name="name"
                                    value={this.state.name}
                                    onChange={(e) => this.setState({ name: e.target.value })}
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>CPF</Label>
                                <Input
                                    type="text"
                                    name="cpf"
                                    value={this.state.cpf}
                                    onChange={(e) => this.setState({ cpf: e.target.value })}
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>Email</Label>
                                <Input
                                    type="email"
                                    name="email"
                                    value={this.state.email}
                                    onChange={(e) => this.setState({ email: e.target.value })}
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>Celular</Label>
                                <Input
                                    type="text"
                                    name="celular"
                                    value={this.state.celular}
                                    onChange={(e) => this.setState({ celular: e.target.value })}
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>Data de Nascimento</Label>
                                <Input
                                    style={{width: '100.1%'}}
                                    type="date"
                                    name="data_nascimento"
                                    value={this.state.data_nascimento}
                                    onChange={(e) => this.setState({ data_nascimento: e.target.value })}
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>Cargo/Função</Label>
                                <Select
                                    name="cargo"
                                    value={this.state.fk_cargo_id}
                                    onChange={(e) => this.setState({ fk_cargo_id: e.target.value })}
                                >
                                    <option value="">Selecione uma cargo/função</option>
                                    {this.state.cargos.map((e) => (
                                        <option value={e.id}>{e.name}</option>
                                    ))}
                                    {/* Adicione mais opções conforme necessário */}
                                </Select>
                            </FormGroup>
                            <FormGroup>
                                <Label>Empresa</Label>
                                <Select
                                    name="empresa"
                                    value={this.state.fk_empresa_id}
                                    onChange={(e) => this.setState({ fk_empresa_id: e.target.value })}
                                    required
                                >
                                    <option value="">Selecione uma empresa</option>
                                    {this.state.empresas.map((e) => (
                                        <option value={e.id}>{e.name}</option>
                                    ))}
                                    {/* Adicione mais opções conforme necessário */}
                                </Select>
                            </FormGroup>
                            <FormGroup>
                                <Label>Filial</Label>
                                <Select
                                    name="filial"
                                    value={this.state.fk_filial_id}
                                    onChange={(e) => this.setState({ fk_filial_id: e.target.value })}
                                    required
                                >
                                    <option value="">Selecione uma filial</option>
                                    {this.state.filiais.map((f) => (
                                        <option value={f.id}>{f.name}</option>
                                    ))}
                                    {/* Adicione mais opções conforme necessário */}
                                </Select>
                            </FormGroup>
                            <FormGroup>
                                <Label>Senha</Label>
                                <div className='container-password'>
                                    <Input
                                        type={passwordview ? "text" : "password"}
                                        name="password"
                                        value={this.state.password}
                                        onChange={(e) => this.setState({ password: e.target.value })}
                                        style={{
                                            marginRight: '-30px'
                                        }}
                                    />
                                    <div className='eye' onClick={() => this.setState({ passwordview: !passwordview })}>
                                        {passwordview ? <FiEye /> : <FiEyeOff />}
                                    </div>
                                </div>

                            </FormGroup>
                            <ContainerButton>
                                <Button type="submit">
                                    {this.state.loading ? <BarLoader color='white' size={30} /> : 'Atualizar'}
                                </Button>
                            </ContainerButton>
                        </Form>
                    </FormContainer>
                )}


                {loadingData && (
                    <Loading>
                        <BarLoader />
                    </Loading>
                )}
            </>
        );
    }
}

export default MinhaConta;
