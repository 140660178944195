const getToken = () => {
    try {
        const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
        const token = user ? user.token : null;
        return token;
    } catch (error) {
        return null;
    }
};

 

module.exports = { getToken };
