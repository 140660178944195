import React, { Component } from 'react';
import axios from 'axios';
import { format, parseISO } from 'date-fns';
import { BarLoader } from 'react-spinners';
import EmpresaRegistration from './FilialRegistration';
import { TableContainer, Table, TableHead, TableRow, TableHeader, TableBody, TableData, Cadastro, CadastroContainer, TableRowHeader, NoReg, Loading } from './styles';
import api from '../../../../services/api';
import { getToken } from '../../../../utils/utils';


class Filiais extends Component {
    state = {
        register: false,
        filiais: [],
        filial: '',
        loading: false,
        filters: [],
        name_filter: '',
        razao_filter: '',
        fantasia_filter: '',
        cnpj_filter: '',
        ie_filter: ''
    };

    async componentDidMount() {
        this.handleGetData();
    };


    handleGetData = async () => {
        this.setState({ loading: true });
        const token = getToken();
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };
        await axios.post(`${api}?route=filial-index`, {}, config).then((response) => {
            this.setState({
                filiais: response.data
            })
        }).catch((err) => {

        });
        this.setState({ loading: false });
    };

    formatDate = (dateString) => {
        const date = parseISO(dateString);
        const formattedDate = format(date, 'dd/MM/yyyy');
        return formattedDate;
    };

    handleFilter = (col, value) => {

        switch (col) {
            case 'razao':
                this.setState({ razao_filter: value });
                break;
            case 'fantasia':
                this.setState({ fantasia_filter: value });
                break;
            case 'cnpj':
                this.setState({ cnpj_filter: value });
                break;
            case 'ie':
                this.setState({ ie_filter: value });
                break;
            default:
                break;
        }

        setTimeout(() => {
            this.handleFilterData();
        }, 100);
    };

    handleFilterData = () => {
        const { filiais, razao_filter, fantasia_filter, cnpj_filter, ie_filter } = this.state;
        console.log(fantasia_filter)
        // Criamos um array de filtros dinâmico
        const filters = [
            { key: 'razao', value: razao_filter, match: (empresa, value) => empresa.razao.toLowerCase().includes(value.toLowerCase()) },
            { key: 'fantasia', value: fantasia_filter, match: (empresa, value) => empresa.name.toLowerCase().includes(value.toLowerCase()) },
            { key: 'cnpj', value: cnpj_filter, match: (empresa, value) => empresa.cnpj.toLowerCase().includes(value.toLowerCase()) },
            { key: 'ie', value: ie_filter, match: (empresa, value) => empresa.insc_estadual.toLowerCase().includes(value.toLowerCase()) },
        ];

        // Filtra os usuários com base nos filtros que foram preenchidos
        const filteredfiliais = filiais.filter(empresa => {
            return filters.every(filter => {
                // Só aplicamos o filtro se o valor estiver preenchido
                return !filter.value || filter.match(empresa, filter.value);
            });
        });

        // Atualiza o estado com os usuários filtrados
        this.setState({ filters: filteredfiliais });
        console.log(filteredfiliais);
    };


    render() {
        const { register, loading, filters } = this.state;
        return (
            <>
                {!loading && register && (
                    <EmpresaRegistration onClose={() => { this.setState({ register: false, filial: '' }); this.handleGetData() }} onReload={() => this.handleGetData()} filial={this.state.filial} />
                )}

                {!loading && !register && (
                    <TableContainer>
                        <CadastroContainer>
                            {/* <Input placeholder='Buscar...' /> */}
                            <Cadastro onClick={() => this.setState({ register: true })}>Cadastrar</Cadastro>
                        </CadastroContainer>
                        <Table>
                            <TableHead>
                                <TableRowHeader>
                                    <TableHeader>ID</TableHeader>
                                    <TableHeader>Razão Social
                                        <input type='text' placeholder='...' onChange={(e) => this.handleFilter('razao', e.target.value)} />
                                    </TableHeader>
                                    <TableHeader>Nome Fantasia
                                        <input type='text' placeholder='...' onChange={(e) => this.handleFilter('fantasia', e.target.value)} />
                                    </TableHeader>
                                    <TableHeader>CNPJ
                                        <input type='text' placeholder='...' onChange={(e) => this.handleFilter('cnpj', e.target.value)} />
                                    </TableHeader>
                                    <TableHeader>IE
                                        <input type='text' placeholder='...' onChange={(e) => this.handleFilter('ie', e.target.value)} />
                                    </TableHeader>
                                    <TableHeader>Data/Registro</TableHeader>
                                </TableRowHeader>
                            </TableHead>
                            <TableBody>
                                {!filters.length ? this.state.filiais.map((filial) => (
                                    <TableRow key={filial.id} onClick={() => this.setState({ register: true, filial: filial })}>
                                        <TableData>{filial.id}</TableData>
                                        <TableData>{filial.razao}</TableData>
                                        <TableData>{filial.name}</TableData>
                                        <TableData>{filial.cnpj}</TableData>
                                        <TableData>{filial.insc_estadual}</TableData>
                                        <TableData>{filial.data_criacao ? this.formatDate(filial.data_criacao) : '----'}</TableData>
                                    </TableRow>
                                )) : ''}

                                {filters.length ? this.state.filters.map((filial) => (
                                    <TableRow key={filial.id} onClick={() => this.setState({ register: true, filial: filial })}>
                                        <TableData>{filial.id}</TableData>
                                        <TableData>{filial.razao}</TableData>
                                        <TableData>{filial.name}</TableData>
                                        <TableData>{filial.cnpj}</TableData>
                                        <TableData>{filial.insc_estadual}</TableData>
                                        <TableData>{filial.data_criacao ? this.formatDate(filial.data_criacao) : '----'}</TableData>
                                    </TableRow>
                                )) : ''}

                            </TableBody>
                        </Table>
                        {!this.state.filiais.length && (
                            <NoReg>
                                <h3>Sem Cadastro de Filial!</h3>
                            </NoReg>
                        )}
                    </TableContainer>
                )}

                {loading && (
                    <Loading>
                        <BarLoader />
                    </Loading>
                )}

            </>

        );
    }
}

export default Filiais;
