// Importações necessárias do React e do react-router-dom
import React from 'react';
import { 
    BrowserRouter as Router, 
    Route, 
    Routes, 
    // Navigate 
} from 'react-router-dom';

// Importação dos componentes das páginas
import SigIn from './pages/SigIn';
import Dashboard from './pages/Dashboard';
// import About from './pages/About';
// import Contact from './pages/Contact';
// import NotFound from './pages/NotFound';

// Definição das rotas principais
function RoutesConfig() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<SigIn />} />
        <Route path="/dashboard" element={<Dashboard />} />
        {/* <Route path="/about" element={<About />} /> */}
        {/* <Route path="/contact" element={<Contact />} /> */}
        {/* <Route path="/home" element={<Navigate to="/" />} /> */}
        {/* <Route path="*" element={<NotFound />} /> */}
      </Routes>
    </Router>
  );
}

export default RoutesConfig;
