import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import * as pdfjsLib from 'pdfjs-dist/webpack';
import { PDFDocument, rgb } from 'pdf-lib';
import { toast } from 'react-toastify';
import axios from 'axios';
import { getToken } from '../../../../../utils/utils';
import api from '../../../../../services/api';
import { Loading, Window, WindowPdf } from './styles';
import SignatureModal from './SignatureModal';

class PdfView extends Component {
  state = {
    showSignatureModal: false,
    signatureDataURL: null,
    loading: false,
    configAssinatura: {
      textXPos: 10,
      textYPos: 20,
      signatureWidth: 260,
      imageXPos: 320,
      imageYPos: 40,
      fontSize: 10,
    },
    user: '',
  };

  containerRef = createRef();
  isRendering = false;

  componentDidMount() {
    this.renderPDF();
    this.handleGetPerfil();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.pdfBlob !== this.props.pdfBlob) {
      this.renderPDF();
    }
    if (prevState.signatureDataURL !== this.state.signatureDataURL && this.state.signatureDataURL) {
      this.addSignatureToPDF();
    }
  }

  async renderPDF() {
    const { pdfBlob } = this.props;

    if (pdfBlob && !this.isRendering) {
      this.isRendering = true;
      const container = this.containerRef.current;

      while (container.firstChild) {
        container.removeChild(container.firstChild);
      }

      const reader = new FileReader();

      reader.onload = async () => {
        const pdfData = new Uint8Array(reader.result);

        try {
          const pdf = await pdfjsLib.getDocument({ data: pdfData }).promise;
          const page = await pdf.getPage(1);
          const displaywidth = window.innerWidth;
          const viewport = page.getViewport({ scale: displaywidth < 600 ? 1.2 : 1.5 });
          const canvas = document.createElement('canvas');
          canvas.height = viewport.height;
          canvas.width = viewport.width;
          container.appendChild(canvas);

          const context = canvas.getContext('2d');
          const renderContext = {
            canvasContext: context,
            viewport: viewport,
          };

          await page.render(renderContext).promise;
        } catch (error) {
          console.error('Erro ao renderizar o PDF', error);
        } finally {
          this.isRendering = false;
        }
      };

      reader.readAsArrayBuffer(pdfBlob);
    }
  }

  addSignatureToPDF = async () => {
    const { configAssinatura } = this.state;
    const token = getToken();
    const config = {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }
    let user = '';
    await axios.get(`${api}?route=user-profile`, config).then((response) => {
      if (response) {
        user = {
          id: response.data.id,
          nome: response.data.name
        };
      }

    }).catch((err) => {
      window.alert('Falha ao obter informações de conta para assinatura!');
      return false;
    });

    const { signatureDataURL } = this.state;
    const container = this.containerRef.current;
    const canvas = container.querySelector('canvas');

    if (canvas && signatureDataURL) {
      const context = canvas.getContext('2d');
      const image = new Image();
      image.src = signatureDataURL;

      image.onload = () => {

        const currentDate = new Date().toLocaleDateString();
        const { id, nome } = user;
        // Adicionar texto com informações do usuário
        context.font = '13px Arial';
        context.fillStyle = 'black';
        const textXPos = configAssinatura.textXPos;
        const textYPos = canvas.height - configAssinatura.textYPos;
        context.fillText(`Assinado por: ${nome || 'N/A'}`, textXPos, textYPos);
        context.fillText(`Data: ${currentDate}`, textXPos, textYPos + 20);
        context.fillText(`ID do Usuário: ${id || 'N/A'}`, textXPos, textYPos + 40);

        // Pega o tamanho da tela do aparelho
        const displaywidth = window.innerWidth;

        // Redimensionar a imagem da assinatura
        const signatureWidth = displaywidth < 600 ? 150 : configAssinatura.signatureWidth; // Largura desejada da assinatura
        const signatureHeight = displaywidth < 600 ?  50  : (image.height / (image.width + 1)) * signatureWidth;

        // Posicionar a assinatura ao lado dos dados
        const imageXPos = textXPos + configAssinatura.imageXPos;
        const imageYPos = textYPos - configAssinatura.imageYPos; // Posicionar logo abaixo do texto
        context.drawImage(image, imageXPos, imageYPos, signatureWidth, signatureHeight);
      };
    }
  };

  openSignatureModal = () => {
    const { assinado } = this.props;
    if (assinado) {
      window.alert('Documento já assinado!');
      return false;
    }
    this.setState({ showSignatureModal: true });
  };

  closeSignatureModal = () => {
    this.setState({ showSignatureModal: false });
  };

  handleSaveSignature = (signatureDataURL) => {
    this.setState({ signatureDataURL, showSignatureModal: false });
  };

  handleGetPerfil = async () => {
    const token = getToken();
    const config = {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }
    const response = await axios.get(`${api}?route=user-profile`, config);
    if (response) {
      return response.data;
    } else {
      return null;
    }
  };

  savePDFWithSignature = async () => {
    const { signatureDataURL } = this.state;
    const { pdfBlob, fileId, fechar } = this.props;
    const user = await this.handleGetPerfil();
    if (!signatureDataURL) {
      window.alert('Assine o documento!');
      return false;
    }

    // Carregar o PDF original
    const pdfDoc = await PDFDocument.load(await pdfBlob.arrayBuffer());
    const pages = pdfDoc.getPages();
    const firstPage = pages[0];

    // Carregar a assinatura como imagem
    const signatureImageBytes = await fetch(signatureDataURL).then((res) => res.arrayBuffer());
    const signatureImage = await pdfDoc.embedPng(signatureImageBytes);

    firstPage.getSize();
    const { textXPos, textYPos, signatureWidth, imageXPos, imageYPos, fontSize } = this.state.configAssinatura;

    // Adicionar texto e assinatura ao PDF
    firstPage.drawText(`Assinado por: ${user.name || 'N/A'} - ID: ${user.id || 'N/A'} Data: ${new Date().toLocaleDateString()}`, {
      x: textXPos,
      y: textYPos,
      size: fontSize,
      color: rgb(0, 0, 0),
    });

    firstPage.drawImage(signatureImage, {
      x: textXPos + imageXPos,
      y: textYPos - imageYPos,
      width: signatureWidth,
      height: (signatureWidth / signatureImage.width) * signatureImage.height,
    });

    // Salvar o PDF com a assinatura
    const signedPdfBytes = await pdfDoc.save();

    const formData = new FormData();
    formData.append('file', new Blob([signedPdfBytes], { type: 'application/pdf' }), 'signed-document.pdf');
    formData.append('file_id', fileId);

    const signatureBlob = await this.dataURLToBlob(signatureDataURL);
    formData.append('assinatura', signatureBlob, 'assinatura.png');
    formData.append('fk_user_id', user.id);

    const token = getToken();

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    await axios
      .post(`${api}?route=file-update`, formData, config)
      .then((response) => {
        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
        fechar();
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
          this.setState({ loading: false });
        } else {
          toast.warn('Falha de conexão!', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
        }
      });
  };

  dataURLToBlob = (dataURL) => {
    return new Promise((resolve) => {
      const byteString = atob(dataURL.split(',')[1]);
      const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);

      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      resolve(new Blob([ab], { type: mimeString }));
    });
  };

  render() {
    const { fechar } = this.props;
    const { showSignatureModal, loading } = this.state;
    return (
      <>
        {loading ? (
          <Loading>
            <div className='loading-container'>
              <h3>Salvando assinatura...</h3>
            </div>
          </Loading>
        ) : (
          ''
        )}
        <Window>
          <WindowPdf>
            <center>
              <div className='containers-button'>
                <button type='button' onClick={this.openSignatureModal} className='btn-assinar'>
                  Assinar
                </button>
                <button type='button' onClick={this.savePDFWithSignature} className='btn-assinar'>
                  Salvar
                </button>
                <button type='button' onClick={() => fechar()} className='btn-assinar'>
                  Fechar
                </button>
              </div>
            </center>
            <div ref={this.containerRef} style={{ textAlign: 'center', marginTop: '20px', padding: '50px' }}>
            </div>
            {showSignatureModal && (
              <SignatureModal onSave={this.handleSaveSignature} onClose={this.closeSignatureModal} />
            )}
          </WindowPdf>
        </Window>
      </>
    );
  }
}

PdfView.propTypes = {
  fileId: PropTypes.number.isRequired,
  fechar: PropTypes.func.isRequired,
  assinado: PropTypes.bool.isRequired,
  pdfBlob: PropTypes.string.isRequired,
};

export default PdfView;
