import { createGlobalStyle } from 'styled-components';
import Angelina from './fonts/Angelina.ttf';
import My_Boyfriends_Handwriting from './fonts/My_Boyfriends_Handwriting.ttf';


export default createGlobalStyle`
    * {
        margin: 0;
    }
     /* Importando fontes personalizadas */
     @font-face {
        font-family: 'Angelina';
        src: url(${Angelina}) format('truetype');
    }
    @font-face {
        font-family: 'My Boyfriends Handwriting';
        src: url(${My_Boyfriends_Handwriting}) format('truetype');
    }
    body {
        font-family: Arial, Helvetica, sans-serif;
    }
`;