import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import { DashboardContainer, TabContainer, TabButton, ContentContainer, } from './styles';

import UserList from './Components/Usuarios';
import Empresas from './Components/Empresas';
import Filiais from './Components/Filiais';
import MinhaConta from './Components/MinhaConta';
import Holerites from './Components/Holerites';
import { getToken } from '../../utils/utils';
import api from '../../services/api';
import HoleritesUser from './Components/HoleritesUser';

class Dashboard extends Component {
    state = {
        activeTab: 'usuarios',
        user: '',
        sigin: false
    };

    async componentDidMount() {
        const token = getToken();
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }
        await axios.get(`${api}?route=user-profile`, config).then((response) => {
            this.setState({
                user: response.data,
                activeTab: response.data && response.data.admin ? 'usuarios' : 'holerites'
            });
        }).catch((err) => { this.setState({ sigin: true }); localStorage.removeItem('user'); });
    };

    handleTabChange = (tab) => {
        this.setState({ activeTab: tab });
    };

    renderContent() {
        const { activeTab } = this.state;

        switch (activeTab) {
            case 'usuarios':
                return <UserList />;
            case 'empresas':
                return <Empresas />;
            case 'filiais':
                return <Filiais />;
            case 'holerites':
                return <Holerites />;
            case 'minha-conta':
                return <MinhaConta />;
            case 'logout':
                return <div><Navigate to="/" /></div>;
            default:
                return null;
        }
    }

    renderContentUser() {
        const { activeTab } = this.state;

        switch (activeTab) {
            case 'holerites':
                return <HoleritesUser />;
            case 'minha-conta':
                return <MinhaConta />;
            case 'logout':
                return <div><Navigate to="/" /></div>;
            default:
                return null;
        }
    }

    render() {
        const { activeTab, sigin, user } = this.state;
        return (
            <DashboardContainer>
                {sigin && <Navigate to="/" />}
                <TabContainer>
                    {user && user.admin ? (
                        <>
                            <TabButton
                                active={activeTab === 'usuarios'}
                                onClick={() => this.handleTabChange('usuarios')}
                            >
                                Usuários
                            </TabButton>
                            <TabButton
                                active={activeTab === 'empresas'}
                                onClick={() => this.handleTabChange('empresas')}
                            >
                                Empresas
                            </TabButton>
                            <TabButton
                                active={activeTab === 'filiais'}
                                onClick={() => this.handleTabChange('filiais')}
                            >
                                Filiais
                            </TabButton>
                        </>
                    ) : ''}
                    <TabButton
                        active={activeTab === 'holerites'}
                        onClick={() => this.handleTabChange('holerites')}
                    >
                        Holerites/Docs
                    </TabButton>
                    <TabButton
                        active={activeTab === 'minha-conta'}
                        onClick={() => this.handleTabChange('minha-conta')}
                    >
                        Minha Conta
                    </TabButton>
                    <TabButton
                        active={activeTab === 'logout'}
                        onClick={() => { localStorage.removeItem('user'); this.handleTabChange('logout') }}
                    >
                        Sair
                    </TabButton>
                </TabContainer>
                {user && user.admin ? <ContentContainer>{this.renderContent()}</ContentContainer> : <ContentContainer>{this.renderContentUser()}</ContentContainer>}

            </DashboardContainer>
        );
    }
}

export default Dashboard;
