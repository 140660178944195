import styled from 'styled-components';

export const TableContainer = styled.div`
  /* padding: 2rem; */
  background-color: #f0f0f0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(185, 153, 153, 0.1);
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const TableHead = styled.thead`
  background-color:  #7e234f;
`;

export const TableRowHeader = styled.tr`
  &:nth-child(even) {
    /* background-color: #f2f2f2; */
    background-color: #7e234f;
  }
  cursor: pointer;
`;

export const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
  cursor: pointer;
  &:hover {
    background-color: #e4e4e4;
  }
`;

export const TableHeader = styled.th`
  padding: 0.5rem;
  text-align: left;
  color: #ffff;
  font-weight: bold;
  font-size: 13px;
`;

export const TableBody = styled.tbody``;

export const TableData = styled.td`
  padding: 0.3rem;
  border-bottom: 1px solid #dddddd;
  font-size: 13px;
  .btn-delete {
    background-color: transparent;
    border: none;
    outline: none;
    color: #bd2323;
    cursor: pointer;
    width: 5%;
    height: 30px;
  }

  .btn-download {
    background-color: transparent;
    border: none;
    outline: none;
    color: #212bf2;
    cursor: pointer;
    width: 5%;
    height: 30px;
  }


  .status {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
`;


export const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
  background-color: #f9f9f9;
`;

export const Form = styled.form`
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  /* max-width: 500px; */
`;

export const FormGroup = styled.div`
  /* margin-bottom: 1.5rem; */
  width: 101%;
  .container-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
  }

  span {
    margin-left: 5px;
    margin-right: 5px;
  }
`;


export const Label = styled.label`
  display: block;
  font-weight: bold;
`;

export const Input = styled.input`
  width: 100%;
  padding: 0.4rem;
  /* min-height: 33px; */
  /* border-radius: 4px; */
  border: ${(props) => props.selected ? '2px solid green' : '1px solid #ccc'};
  /* font-size: 1rem; */
  outline: none;
  background-color: ${(props) => props.selected ? '#8aff7b' : ''};
  &:active {
    background-color: #e8e8e8;
    color: #333333;
  }

  &:hover {
    background-color: #e8e8e8;
    color: #333333;
  }
`;

export const ButtonSave = styled.button`
  width: 150px;
  padding: 0.75rem;
  background-color: #a0326f;
  color: white;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #7e234f;
  }

`;

export const Select = styled.select`
  width: 100%;
  min-height: 30px;
  border: 1px solid #ddd;
  /* font-size: 1rem; */
  outline: none;
  &:active {
    background-color: #e8e8e8;
    color: #333333;
  }

  &:hover {
    background-color: #e8e8e8;
    color: #333333;
  }
`;

export const ContainerButton = styled.div`
  width: 101%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  padding-top: 5px;
`;

export const Button = styled.button`
  width: 100px;
  height: 30px;
  background-color: ${(props) => props.disabled ? '#c47da4' : '#a0326f'} ;
  color: white;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: #7e234f;
  }
`;

export const ButtonC = styled.button`
  width: 100px;
  height: 30px;
  background-color: #999999;
  color: white;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: #6e6e6e;
  }
  margin-left: 5px;
  margin-right: 5px;
`;

export const ButtonAdd = styled.button`
    width: 50px;
    padding: 0.85rem;
    background-color: #159b4b;
    color: white;
    border: none;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background-color: #10793a;
    }
    margin-left: 5px;
`;

export const Window = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 120vh;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: 999;
`;

export const FormGroupAdd = styled.div`
  width: 100%;
`;


export const FormAdd = styled.form`
    background-color: #ffffff;
    border: 2px solid red;
    width: 80%;
    min-height: 400px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    margin-top: 20px;


    .label {
      width: 81%;
    }

    .footer-save {
      width: 81%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-top: 10px;
    }

    .header {
      width: 81%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-top: 10px;

      button {
        background-color: transparent;
        height: 30px;
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        outline: none;
        cursor: pointer;
      }
    }

    input {
      width: 80%;
      border: 1px solid #ccc;
      /* font-size: 1rem; */
      outline: none;
      height: 30px;
      &:active {
        background-color: #a0326f;
        color: white;
      }

      &:hover {
        background-color: #a0326f;
        color: white;
      }
    }
`;


export const InputAdd = styled.input`
  width: 100%;
  height: 40px;
  border: 1px solid #ccc;
  font-size: 1rem;
  outline: none;

  &:active {
    background-color: #a0326f;
    color: white;
  }

  &:hover {
    background-color: #a0326f;
    color: white;
  }
`;

export const NoReg = styled.div`
  width: 100%;
  height: 200px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50px;
`;


export const FormHeader = styled.div`
  width: 98%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 10px;
  margin-top: -40px;
`;

export const Close = styled.button`
  background-color: #fe8585;
  color: white;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 30px;
  min-width: 80px;
  margin-right: -4px;
  cursor: pointer;
  span {
    margin-right: 5px;
    margin-left: 5px;
  }
`;

