import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { BarLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import { FiEye, FiEyeOff } from "react-icons/fi";
import { ImExit } from "react-icons/im";
import { MdAdd, MdClose, MdDelete, MdEdit } from 'react-icons/md';
import {
  FormContainer,
  Form,
  FormGroup,
  Label,
  Input,
  Select,
  Button,
  ContainerButton,
  ButtonC,
  ButtonAdd,
  Window,
  FormAdd,
  // FormGroupAdd,
  // InputAdd,
  ButtonSave,
  ButtonUpdate,
  ButtonDelete,
  FormHeader,
  Close
} from './styles';
import api from '../../../../../services/api';
import { getToken } from '../../../../../utils/utils';

class UserRegistration extends Component {
  state = {
    name: '',
    cpf: '',
    email: '',
    celular: '',
    password: '',
    data_nascimento: '',
    fk_empresa_id: '',
    fk_filial_id: '',
    fk_cargo_id: '',
    id_user_registered: '',
    loading: false,
    empresas: [],
    filiais: [],
    cargos: [],
    addCargo: false,
    name_cargo: '',
    descricao_cargo: '',
    loading_cargo: false,
    passwordview: false,
    active: 1,
  };

  async componentDidMount() {
    this.handleGetData();
    const { user } = this.props;
    if (user) {
      this.setState({
        id_user_registered: user.id,
        name: user.name,
        cpf: user.cpf,
        email: user.email,
        celular: user.celular,
        data_nascimento: user.data_nascimento,
        fk_empresa_id: user.fk_empresa_id,
        fk_filial_id: user.fk_filial_id,
        fk_cargo_id: user.fk_cargo_id,
        active: user.active
      });
    } else {
      setTimeout(() => {
        this.setState({
          email: '',
          password: ''
        })
      }, 1000);
    }
    window.addEventListener('keydown', this.handleKeyDown);
  };

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
  };

  handleKeyDown = (event) => {
    const { onClose } = this.props;
    if (event.key === 'Escape') {
      onClose();
    }
  };


  handleGetData = async () => {
    const token = getToken();
    const config = {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }

    await axios.post(`${api}?route=filial-index`, {}, config).then((response) => {
      this.setState({
        filiais: response.data
      })
    }).catch((err) => {

    });
    await axios.post(`${api}?route=empresa-index`, {}, config).then((response) => {
      this.setState({
        empresas: response.data
      })
    }).catch((err) => {

    });

    await axios.get(`${api}?route=cargo-index`, config).then((response) => {
      this.setState({
        cargos: response.data
      })
    }).catch((err) => {

    });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { name, cpf, email, celular, password, data_nascimento, fk_empresa_id, fk_filial_id, fk_cargo_id, id_user_registered, active } = this.state;

    if (!id_user_registered) {
      const data = {
        name,
        cpf,
        email,
        celular,
        password,
        data_nascimento,
        fk_empresa_id,
        fk_filial_id,
        fk_cargo_id
      };
      const token = getToken();
      const config = {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
      await axios.post(`${api}?route=users-create`, data, config).then((response) => {
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }).catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          this.setState({ loading: false });
        } else {
          toast.warn('Falha de conexão!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          this.setState({ loading: false });
        }
      });
    } else {
      const data = {
        id: id_user_registered,
        name,
        cpf,
        email,
        celular,
        password,
        data_nascimento,
        fk_empresa_id,
        fk_filial_id,
        fk_cargo_id,
        active
      };
      const token = getToken();
      const config = {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
      await axios.post(`${api}?route=user-update`, data, config).then((response) => {
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }).catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          this.setState({ loading: false });
        } else {
          toast.warn('Falha de conexão!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          this.setState({ loading: false });
        }
      });
    }
  };

  handleSaveCargo = async (e) => {
    e.preventDefault();
    const { name_cargo, descricao_cargo, fk_cargo_id } = this.state;
    const token = getToken();
    const config = {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    };

    if (!fk_cargo_id) {
      const data = {
        name: name_cargo,
        descricao: descricao_cargo,
      };
      // CADASTRO
      await axios.post(`${api}?route=cargo-create`, data, config).then(async (response) => {
        await this.handleGetData();
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setTimeout(() => {
          this.setState({ fk_cargo_id: this.state.cargos.length && this.state.cargos[this.state.cargos.length - 1].id })
        }, 500);
      }).catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          this.setState({ loading: false });
        } else {
          toast.warn('Falha de conexão!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          this.setState({ loading: false });
        }
      });
    } else {
      // UPDPATE
      const data_update = {
        id: fk_cargo_id,
        name: name_cargo,
        descricao: descricao_cargo,
      };
      await axios.post(`${api}?route=cargo-update`, data_update, config).then((response) => {
        this.handleGetData();
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }).catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          this.setState({ loading: false });
        } else {
          toast.warn('Falha de conexão!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          this.setState({ loading: false });
        }
      });

    }


  };

  handleEditCargo = () => {
    const { cargos, fk_cargo_id } = this.state;
    const cargo = cargos.find((c) => Number(c.id) === Number(fk_cargo_id));
    console.log(cargo)
    if (cargo) {
      this.setState({
        addCargo: true,
        name_cargo: cargo.name,
        descricao_cargo: cargo.descricao,
      });
    };
  };

  handleDeleteCargo = async () => {
    const { fk_cargo_id } = this.state;
    this.setState({ loading: true });
    const token = getToken();
    const config = {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    };
    const data = {
      id: fk_cargo_id,
    };

    await axios.post(`${api}?route=cargo-delete`, data, config).then((response) => {
      this.handleGetData();
      toast.success(response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setTimeout(() => { this.setState({ fk_cargo_id: '', loading: false }) }, 100);
    }).catch((err) => {
      if (err.response) {
        toast.error(err.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        this.setState({ loading: false });
      } else {
        toast.warn('Falha de conexão!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        this.setState({ loading: false });
      }
    });
  }

  render() {
    const { onClose } = this.props;
    const { loading, id_user_registered, addCargo, fk_cargo_id, passwordview } = this.state;
    return (
      <>
        {addCargo && (
          <Window>
            <FormAdd onSubmit={this.handleSaveCargo}>
              <div className='header'>
                <h3>Cadastro Cargo/Função</h3>
                <button type='button' onClick={() => this.setState({ addCargo: false })}>
                  <MdClose size={20} />
                </button>
              </div>
              <div className='label'>
                <label>Nome:</label>
              </div>
              <input required type='text' name='name-cargo' onChange={(e) => this.setState({ name_cargo: e.target.value })} value={this.state.name_cargo} />
              <div className='label'>
                <label>Descrição:</label>
              </div>
              <input type='text' name='descricao-cargo' onChange={(e) => this.setState({ descricao_cargo: e.target.value })} value={this.state.descricao_cargo} />
              <div className='footer-save'>
                <ButtonSave>
                  {fk_cargo_id ? 'Atualizar' : 'Salvar'}
                </ButtonSave>
              </div>
            </FormAdd>
          </Window>
        )}

        <FormContainer>
          <Form onSubmit={this.handleSubmit}>
            <FormHeader>
              <Close type='button' onClick={() => onClose()}>
                <span>Esc</span>
                <ImExit size={20} />
              </Close>
            </FormHeader>
            <FormGroup>
              <Label>Nome</Label>
              <Input
                type="text"
                name="name"
                value={this.state.name}
                onChange={(e) => this.setState({ name: e.target.value })}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label>CPF</Label>
              <Input
                type="text"
                name="cpf"
                value={this.state.cpf}
                onChange={(e) => this.setState({ cpf: e.target.value })}
                required
                maxLength={11}
              />
            </FormGroup>
            <FormGroup>
              <Label>Email</Label>
              <Input
                type="email"
                name="email"
                value={this.state.email}
                onChange={(e) => this.setState({ email: e.target.value })}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label>Celular</Label>
              <Input
                type="text"
                name="celular"
                value={this.state.celular}
                onChange={(e) => this.setState({ celular: e.target.value })}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label>Data de Nascimento</Label>
              <Input
                type="date"
                name="data_nascimento"
                value={this.state.data_nascimento}
                onChange={(e) => this.setState({ data_nascimento: e.target.value })}
                required
              />
            </FormGroup>
            <FormGroup >
              <Label>Cargo/Função</Label>
              <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexDirection: 'row',
                // padding: '1px',
                width: '100%'
              }}>
                <Select
                  name="cargo"
                  value={this.state.fk_cargo_id}
                  onChange={(e) => this.setState({ fk_cargo_id: e.target.value })}
                  required
                >
                  <option value="">Selecione uma cargo/função</option>
                  {this.state.cargos.map((c) => (
                    <option value={c.id}>{c.name}</option>
                  ))}
                  {/* Adicione mais opções conforme necessário */}
                </Select>
                {fk_cargo_id && (
                  <ButtonDelete type='button' onClick={() => this.handleDeleteCargo(fk_cargo_id)}>
                    <MdDelete />
                  </ButtonDelete>
                )}
                {fk_cargo_id && (
                  <ButtonUpdate type='button' onClick={() => this.handleEditCargo(fk_cargo_id)}>
                    <MdEdit />
                  </ButtonUpdate>
                )}
                <ButtonAdd type='button' onClick={() => this.setState({ addCargo: true, fk_cargo_id: '', name_cargo: '', descricao_cargo: '' })}>
                  <MdAdd />
                </ButtonAdd>
              </div>

            </FormGroup>
            <FormGroup>
              <Label>Empresa</Label>
              <Select
                name="empresa"
                value={this.state.fk_empresa_id}
                onChange={(e) => this.setState({ fk_empresa_id: e.target.value })}
                required
              >
                <option value="">Selecione uma empresa</option>
                {this.state.empresas.map((e) => (
                  <option value={e.id}>{e.name}</option>
                ))}
                {/* Adicione mais opções conforme necessário */}
              </Select>
            </FormGroup>
            <FormGroup>
              <Label>Filial</Label>
              <Select
                name="filial"
                value={this.state.fk_filial_id}
                onChange={(e) => this.setState({ fk_filial_id: e.target.value })}
                required
              >
                <option value="">Selecione uma filial</option>
                {this.state.filiais.map((f) => (
                  <option value={f.id}>{f.name}</option>
                ))}
                {/* Adicione mais opções conforme necessário */}
              </Select>
            </FormGroup>
            <FormGroup>
              <Label>Senha</Label>
              <div className='container-password'>
                <Input
                  type={passwordview ? "text" : "password"}
                  autoComplete="off"
                  name="new-password"
                  value={this.state.password}
                  onChange={(e) => this.setState({ password: e.target.value })}
                  style={{
                    // marginRight: '-30px'
                  }}
                />
                <div className='eye' onClick={() => this.setState({ passwordview: !passwordview })}>
                  {passwordview ? <FiEye /> : <FiEyeOff />}
                </div>
              </div>

            </FormGroup>
            <FormGroup>
              <Label>Status de Conta</Label>
              <Select
                name="filial"
                value={this.state.active}
                onChange={(e) => this.setState({ active: e.target.value })}
                required
              >
                <option value="1">Ativado</option>
                <option value="0">Desativado</option>
              </Select>
            </FormGroup>
            <ContainerButton>
              <ButtonC type="button" onClick={() => onClose()}>Cancelar</ButtonC>
              <Button type="submit">
                {loading ? <BarLoader color='white' size={30} /> : (
                  id_user_registered ? 'Atualizar' : 'Cadastrar'
                )}
              </Button>
            </ContainerButton>
          </Form>
        </FormContainer>
      </>
    );
  }
}

UserRegistration.propTypes = {
  onClose: PropTypes.func.isRequired,
  user: PropTypes.func.isRequired
}

export default UserRegistration;
