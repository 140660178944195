import styled from 'styled-components';

export const Window = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const WindowPdf = styled.div`
    width: 100%;
    height: 100vh;
    background-color: #ffffff;
    overflow: auto;
    .btn-assinar {
        height: 30px;
    }
    .containers-button {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        position: fixed;
        
        @media only screen and (max-width: 600px) {
           bottom: 0;
           width: 100%;
        }

        @media only screen and (min-width: 600px) {
           top: 0;
           width: 100%;
        }

    }

    button {
        display: flex;
        align-items: center;
        justify-content: center;
        /* height: 40px; */
        width: 115px;
        background-color: #4b48e7;
        border: none;
        outline: none;
        color: white;
        cursor: pointer;
        margin: 2px;
    }
`;

export const Loading = styled.div`
    z-index: 999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;

    .loading-container {
        background-color: #ffffff;
        padding: 20px;
    }
`;