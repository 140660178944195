import styled from 'styled-components';

export const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
  background-color: #f9f9f9;
`;

export const Form = styled.form`
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  /* max-width: 500px; */
`;

export const FormGroup = styled.div`
  /* margin-bottom: 1.5rem; */
`;

export const Label = styled.label`
  display: block;
  font-weight: bold;
`;

export const Input = styled.input`
  width: 100%;
  min-height: 30px;
  border: 1px solid #ccc;
  outline: none;
  ;
  &:active {
    background-color: #e8e8e8;
    color: #333333;
  }

  &:hover {
    background-color: #e8e8e8;
    color: #333333;
  }
`;
export const Select = styled.select`
  width: 100%;
  min-height: 30px;
  border: 1px solid #ddd;
  outline: none;
  &:active {
    background-color: #e8e8e8;
    color: #333333;
  }
  &:hover {
    background-color: #e8e8e8;
    color: #333333;
  }
`;

export const ContainerButton  = styled.div`
  width: 101%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  padding-top: 5px;
`;

export const Button = styled.button`
  width: 100px;
  background-color: #a0326f;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  min-height: 30px;
  &:hover {
    background-color: #7e234f;
  }
`;

export const ButtonC = styled.button`
  width: 100px;
  background-color: #999999;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  min-height: 30px;
  &:hover {
    background-color: #6e6e6e;
  }
  margin-left: 5px;
  margin-right: 5px;
`;


export const FormHeader = styled.div`
  /* border: 1px solid red; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const Close = styled.button`
  background-color: #fe8585;
  color: white;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 30px;
  min-width: 80px;
  margin-right: -4px;
  cursor: pointer;
  span {
    margin-right: 5px;
    margin-left: 5px;
  }
`;


