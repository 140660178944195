import styled from 'styled-components';

export const TableContainer = styled.div`
  /* padding: 2rem; */
  background-color: #f0f0f0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(185, 153, 153, 0.1);
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const TableHead = styled.thead`
  background-color: #7e234f;
`;

export const TableRowHeader = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
  cursor: pointer;
`;

export const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
  cursor: pointer;
  &:hover {
    background-color: #e4e4e4;
  }
`;

export const TableHeader = styled.th`
  padding: 0.5em;
  text-align: left;
  color: white;
  font-weight: bold;
  font-size: 13px;
  select, input  {
    width: 60px;
    margin-left: 5px;
    border: none;
    outline: none;
  }
`;

export const TableBody = styled.tbody``;

export const TableData = styled.td`
  padding: 0.5rem;
  border-bottom: 1px solid #dddddd;
  font-size: 13px;
`;

export const Cadastro = styled.button`
  width: 100px;
  height: 40px;
  background-color: #138fe1;
  color: white;
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  &:active {
    background-color: #0f70b0;
  }
`;

export const CadastroContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const Input = styled.input`
  flex: 1;
  height: 38px;
  border: none;
  outline: none;
  color: #333333;
  font-size: 15px;
`;

export const NoReg = styled.div`
  width: 99.8%;
  height: 200px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
`;


export const Loading = styled.div`
    width: 99.8%;
    height: 200px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
`;
