import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MdClose, MdScreenLockRotation } from "react-icons/md";
import { SignatureContainer, SignatureCanvas, NoRotation } from './styles';

class SignatureModal extends Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
    this.isDrawing = false;
    this.state = {
      fontFamily: 'Angelina',
      signatureText: '', // Texto que será digitado pelo usuário
    };
  }

  componentDidMount() {
    this.setupCanvas();
    document.body.style.overflow = 'hidden'; // Evita o movimento da página quando o modal está aberto
    window.matchMedia("(orientation: portrait)").addEventListener("change", e => {
      const portrait = e.matches;
      const width = window.innerWidth;
      console.log(width)
      if (portrait && width < 600) {
        // do something
        console.log('Rotação desativado');
        if (document.getElementById('norotation')) {
          document.getElementById('norotation').style.display = 'flex';
        }
      } else {
        // do something else
        console.log('Rotação ativado');
        if (document.getElementById('norotation')) {
          document.getElementById('norotation').style.display = 'none';
        }

      }
    });

    setTimeout(() => {
      this.handleOrientation();
    }, 200);
  }

  componentWillUnmount() {
    const canvas = this.canvasRef.current;
    this.removeCanvasEvents(canvas);
    document.body.style.overflow = 'auto'; // Restaura o movimento da página ao fechar o modal
    this.unlockScreenOrientation();
    this.exitFullscreen();
  }

  handleOrientation = () => {
    const e = window.matchMedia("(orientation: portrait)");
    const portrait = e.matches;
    const width = window.innerWidth;
    if (portrait && width < 800) {
      if (document.getElementById('norotation')) {
        document.getElementById('norotation').style.display = 'flex';
      }
    } else {
      if (document.getElementById('norotation')) {
        document.getElementById('norotation').style.display = 'none';
      }
      setTimeout(() => {
        this.handleFullscreen();
      }, 1);
    }
  };

  handleFullscreen = () => {
    if (window.screen.orientation && window.screen.orientation.lock) {
      window.screen.orientation.lock('landscape').catch((err) => {
        console.error('Falha ao bloquear a orientação: ', err);
      });
    }
    const requestFullscreen = document.documentElement.requestFullscreen ||
      document.documentElement.mozRequestFullScreen ||
      document.documentElement.webkitRequestFullscreen ||
      document.documentElement.msRequestFullscreen;
    if (requestFullscreen) {
      requestFullscreen.call(document.documentElement).catch((err) => {
        console.error('Falha ao entrar em modo de tela cheia: ', err);
      });
    }
  };

  unlockScreenOrientation = () => {
    if (window.screen.orientation && window.screen.orientation.unlock) {
      window.screen.orientation.unlock();
    }
  };

  exitFullscreen = () => {
    const exitFullscreen = document.exitFullscreen ||
      document.mozCancelFullScreen ||
      document.webkitExitFullscreen ||
      document.msExitFullscreen;
    if (exitFullscreen) {
      exitFullscreen.call(document).catch((err) => {
        console.error('Falha ao sair do modo de tela cheia: ', err);
      });
    }
  };

  setupCanvas() {
    const canvas = this.canvasRef.current;
    const context = canvas.getContext('2d');
    canvas.width = canvas.offsetWidth;
    canvas.height = canvas.offsetHeight;

    // Configuração inicial do canvas
    context.lineWidth = 3;
    context.lineCap = 'round';
    context.strokeStyle = 'blue'; // A assinatura será desenhada em azul
    this.drawGuideLine(context, canvas);

    // Adicionar eventos de desenho
    this.addCanvasEvents(canvas);
  }

  addCanvasEvents(canvas) {
    canvas.addEventListener('mousedown', this.startDrawing);
    canvas.addEventListener('mouseup', this.stopDrawing);
    canvas.addEventListener('mousemove', this.draw);
    canvas.addEventListener('mouseleave', this.stopDrawing);

    canvas.addEventListener('touchstart', this.startDrawing);
    canvas.addEventListener('touchend', this.stopDrawing);
    canvas.addEventListener('touchmove', this.draw);
  }

  removeCanvasEvents(canvas) {
    canvas.removeEventListener('mousedown', this.startDrawing);
    canvas.removeEventListener('mouseup', this.stopDrawing);
    canvas.removeEventListener('mousemove', this.draw);
    canvas.removeEventListener('mouseleave', this.stopDrawing);

    canvas.removeEventListener('touchstart', this.startDrawing);
    canvas.removeEventListener('touchend', this.stopDrawing);
    canvas.removeEventListener('touchmove', this.draw);
  }

  drawGuideLine(context, canvas) {
    context.beginPath();
    context.lineWidth = 1;
    context.strokeStyle = 'gray';
    const centerY = canvas.height / 2;
    context.moveTo(10, centerY);
    context.lineTo(canvas.width - 10, centerY);
    context.stroke();
    context.closePath();
    context.strokeStyle = 'blue';
  }

  startDrawing = (e) => {
    this.isDrawing = true;
    const canvas = this.canvasRef.current;
    const context = canvas.getContext('2d');
    const rect = canvas.getBoundingClientRect();
    const { clientX, clientY } = this.getClientCoords(e);
    context.beginPath();
    context.moveTo(
      (clientX - rect.left) * (canvas.width / rect.width),
      (clientY - rect.top) * (canvas.height / rect.height)
    );
  };

  stopDrawing = () => {
    if (!this.isDrawing) return;
    this.isDrawing = false;
    const canvas = this.canvasRef.current;
    const context = canvas.getContext('2d');
    context.closePath();
  };

  draw = (e) => {
    if (!this.isDrawing) return;
    e.preventDefault();
    const canvas = this.canvasRef.current;
    const context = canvas.getContext('2d');
    const rect = canvas.getBoundingClientRect();
    const { clientX, clientY } = this.getClientCoords(e);
    context.lineTo(
      (clientX - rect.left) * (canvas.width / rect.width),
      (clientY - rect.top) * (canvas.height / rect.height)
    );
    context.stroke();
  };

  getClientCoords = (e) => {
    if (e.touches && e.touches.length > 0) {
      return { clientX: e.touches[0].clientX, clientY: e.touches[0].clientY };
    }
    return { clientX: e.clientX, clientY: e.clientY };
  };

  handleClear = () => {
    const canvas = this.canvasRef.current;
    const context = canvas.getContext('2d');
    context.clearRect(0, 0, canvas.width, canvas.height);
    this.drawGuideLine(context, canvas);
  };

  handleSave = () => {
    const canvas = this.canvasRef.current;
    const dataURL = canvas.toDataURL('image/png');
    this.props.onSave(dataURL);
  };

  handleTextChange = (e) => {
    this.setState({ signatureText: e.target.value }, () => {
      this.handleDrawText();
    });
  };

  handleDrawText = () => {
    const { fontFamily } = this.state;
    const canvas = this.canvasRef.current;
    const context = canvas.getContext('2d');
    context.clearRect(0, 0, canvas.width, canvas.height); // Limpar o canvas
    this.drawGuideLine(context, canvas); // Redesenhar a linha guia
    const displayWidth = window.innerWidth;
    context.font = displayWidth < 600 ? `50px '${fontFamily}'` : `90px '${fontFamily}'`; // Estilo de fonte de assinatura
    context.fillStyle = 'blue';
    context.fillText(this.state.signatureText, 20, canvas.height / 2.7 + 20); // Desenhar o texto
  };

  render() {
    const { onClose } = this.props;
    const { signatureText, fontFamily } = this.state;
    return (
      <>
        <NoRotation id="norotation">
          <div>
            <div className='header'>
              <button disabled className='btnClose' style={{ maxWidth: '30px !important', display: 'none' }} onClick={() => {
                document.getElementById('norotation').style.display = 'none';
              }}  >
                <MdClose size={25} color='red' />
              </button>
            </div>
            <div className='body'>
              <h2>Rotação desativada!</h2>
              <MdScreenLockRotation size={30} />
            </div>
          </div>
        </NoRotation>

        <SignatureContainer>
          <div className="signature-box">
            <h4>Assine aqui</h4>
            <SignatureCanvas ref={this.canvasRef} />
            <div className='container-input-assinatura'> 
              <input
                type="text"
                value={signatureText}
                onChange={this.handleTextChange}
                placeholder="Digite aqui para gerar a assinatura"
              />
              <span>Fonte:</span>
              <select value={fontFamily} onChange={(e) => this.setState({ fontFamily: e.target.value })}>
                <option value='Angelina'>Angelina</option>
                <option value='My Boyfriends Handwriting'>My Boyfriends Handwriting</option>
              </select>
            </div>

            <div className="buttons">
              <button type="button" onClick={this.handleClear}>Limpar</button>
              <button type="button" onClick={this.handleSave}>Salvar</button>
              <button type="button" onClick={onClose}>Cancelar</button>
            </div>
          </div>
        </SignatureContainer>
      </>
    );
  }
}

SignatureModal.propTypes = {
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SignatureModal;
