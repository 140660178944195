import styled from "styled-components";

export const SignatureContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  .container-input-assinatura {
    /* border: 1px solid red; */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    input {
      width: 53%;
      margin-right: 2px;
      border: 1px solid #333;
      outline: none;
    }

    select {
      margin-left: 2px;
      border: 1px solid #333;
      outline: none;
    }
  }
  .signature-box {
    background: white;
    padding: 10px;
    /* border-radius: 1px; */
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 95%; 
    max-width: 100%; // Define um limite máximo de largura */
  }

  .buttons {
    display: flex;
    justify-content: space-around;
    /* margin-top: 10px; */

    button {
      background-color: #4b48e7;
      color: white;
      border: none;
      cursor: pointer;
      /* width: 100%; */
      transition: background-color 0.2s;
      &:hover {
        background-color: #3a38c4;
      }

      @media only screen and (max-width: 600px) {
            height: 30px;
      }
      @media only screen and (min-width: 600px) {
            height: 30px;
            /* width: 10% !important; */
      }
    }
  }
`;

export const SignatureCanvas = styled.canvas`
  border: 1px solid #ddd;
  width: 90vw;
  height: 60vh; /* Usa uma altura flexível baseada na altura da viewport */
  background-color: white;
`;

export const NoRotation = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 999;
    height: 100vh;
    width: 100%;
    display: none;
    align-items: center;
    justify-content: center;
    div {
      height: 50%;
      width: 80%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      background-color: #ffffff;
    }

    .btnClose {
      max-width: 30px !important;
      height: 30px;
      background-color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    .header {
      height: 30px;
      width: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
    }
    .body {
      height: 80%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
`;

