import styled from 'styled-components';

// Estilização usando styled-components
export const SignInContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
  /* background-color: #f0f0f0; */


  background-image: url(${(props) => props.image});
  background-size: cover;   /* Faz a imagem cobrir todo o container */
  background-position: center;  /* Centraliza a imagem */
  width: 100%;  /* Defina a largura que precisar */
  height: 100vh;  /* Defina a altura que precisar */
`;

export const SignInForm = styled.form`
  background: rgba(255, 255, 255, 0.8);
  padding: 2rem;
  /* border-radius: 8px; */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 300px;
  min-height: 400px;
  width: 100%;

  @media only screen and (max-width: 600px) {
    /* Estilos aplicados quando a largura da tela é de pelo menos 600px */
    min-height: 90vh;
  }
`;

export const Title = styled.h2`
  text-align: center;
  margin-bottom: 2rem;
`;

export const InputGroup = styled.div`
  margin-bottom: 1rem;
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
`;

export const Input = styled.input`
  width: 100%;
  padding: 0.5rem;
  /* border-radius: 4px; */
  border: 1px solid #ccc;
  font-size: 1rem;
  outline: none;

  &:active {
    background-color: #e8e8e8;
    color: #333333;
  }

  &:hover {
    background-color: #e8e8e8;
    color: #333333;
  }
`;

export const SubmitButton = styled.button`
  width: 100%;
  padding: 0.75rem;
  margin-top: 1.5rem;
  border: none;
  background-color: #a0326f;
  color: white;
  font-size: 1rem;
  cursor: pointer;

  &:hover {
    background-color: #7e234f;
  }
`;

export const BackgroundDiv = styled.div`
  background-image: url(${(props) => props.image});
  background-size: cover;   /* Faz a imagem cobrir todo o container */
  background-position: center;  /* Centraliza a imagem */
  width: 100%;  /* Defina a largura que precisar */
  height: 400px;  /* Defina a altura que precisar */
`;


export const Footer = styled.div`
  min-height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: -50px;
  margin-top: 30px;
  span {
    font-size: 12px;
    color: #333;
    font-weight: bold;
  }
`;