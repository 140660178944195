import React, { Component } from 'react';
// import HoleriteUpload from './HoleriteUpload';
import {
    MdCheck,
    MdClose,
    // MdDelete,
    MdDownload,
    // MdAdd,
    // MdUpload,
    // MdClose 
} from 'react-icons/md';
import axios from 'axios';
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableHeader,
    TableBody,
    TableData,
    // Cadastro, 
    CadastroContainer,
    TableRowHeader,
    // Input,
    NoReg
} from './styles';
import api from '../../../../services/api';
import { getToken } from '../../../../utils/utils';
// import { MdUpload } from 'react-icons/md';
import PdfView from './PdfView';
import { toast } from 'react-toastify';
import { BarLoader } from 'react-spinners';

class HoleritesUser extends Component {
    state = {
        users: [],
        empresas: [],
        filial: [],
        cargo: [],
        user: '',
        files: [],
        pdf: '',
        fileId: '',
        assinado: false,
        loading: false,
        years: [],
        filters: [],
        name_filter: '',
        mes_filter: '',
        ano_filter: '',
        urlPdf: '',
    };

    async componentDidMount() {
        this.handleGetFiles();
    };

    handleGetFiles = async () => {
        this.setState({ loading: true });
        const token = getToken();
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };
        await axios.post(`${api}?route=file-index`, {}, config).then((response) => {
            this.setState({
                files: response.data ? response.data.reverse() : [],
                loading: false
            });
            setTimeout(() => {
                this.handleYearsForFilter();
            }, 100);
        }).catch((err) => {
            this.setState({
                files: [],
                loading: false
            })
        });
    };

    handleMont = (numeroMes) => {
        let nomeMes;
        switch (numeroMes) {
            case 1:
                nomeMes = "Janeiro";
                break;
            case 2:
                nomeMes = "Fevereiro";
                break;
            case 3:
                nomeMes = "Março";
                break;
            case 4:
                nomeMes = "Abril";
                break;
            case 5:
                nomeMes = "Maio";
                break;
            case 6:
                nomeMes = "Junho";
                break;
            case 7:
                nomeMes = "Julho";
                break;
            case 8:
                nomeMes = "Agosto";
                break;
            case 9:
                nomeMes = "Setembro";
                break;
            case 10:
                nomeMes = "Outubro";
                break;
            case 11:
                nomeMes = "Novembro";
                break;
            case 12:
                nomeMes = "Dezembro";
                break;
            default:
                nomeMes = "Mês inválido";
                break;
        }

        return nomeMes;
    };

    handleDownload = async (id, name) => {
        const { files } = this.state;

        const filed = files.find((f) => f.id === id);

        if (filed.assinado === 0) {
            window.alert('Assine para download!');
            return false;
        }

        try {
            const token = getToken(); // Função para obter o token Bearer
            const data = {
                file_id: id
            };

            // Realiza a requisição POST para obter o arquivo
            const response = await axios.post(`${api}?route=file-download`, data, {
                responseType: 'blob', // Configura o tipo de resposta para blob
                headers: {
                    Authorization: `Bearer ${token}`, // Inclui o token Bearer no cabeçalho
                },
            });

            // Cria um URL para o arquivo retornado
            const url = window.URL.createObjectURL(new Blob([response.data]));
            this.setState({ urlPdf: url });
            // Cria um link e faz o download do arquivo
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', name); // Nome do arquivo para o download
            document.body.appendChild(link);
            link.click();

            // Remove o link do DOM após o download
            link.remove();

            // Revoga o URL para liberar memória
            window.URL.revokeObjectURL(url);
        } catch (err) {
            if (err.response) {
                toast.error(err.response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                this.setState({ loading: false });
            } else {
                toast.warn('Falha de conexão!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
                this.setState({ loading: false });
            }
        }
    };

    handleDownloadPDF = async (id, index) => {
        const { files } = this.state;

        const filed = files.find((f) => f.id === id);

        this.setState({
            fileId: id,
            assinado: filed.assinado
        });

        try {
            const token = getToken(); // Função para obter o token Bearer
            const data = {
                file_id: id
            };
            // Realiza a requisição POST para obter o arquivo
            await axios.post(`${api}?route=file-download`, data, {
                responseType: 'blob', // Configura o tipo de resposta para blob
                headers: {
                    Authorization: `Bearer ${token}`, // Inclui o token Bearer no cabeçalho
                },
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                this.setState({
                    pdf: response.data,
                    urlPdf: url
                });
            }).catch((err) => {
                if (err.response) {
                    console.log('Operação inválida!')
                }
            });
        } catch (error) {
            window.alert(`Erro ao baixar o arquivo , erro: ${error}`);
        }
    };

    handleFilter = (col, value) => {
        switch (col) {
            case 'name':
                this.setState({ name_filter: value });
                break;
            case 'mes':
                this.setState({ mes_filter: value });
                break;
            case 'ano':
                this.setState({ ano_filter: value });
                break;
            default:
                break;
        }
        setTimeout(() => {
            this.handleFilterData();
        }, 100);
    };

    handleFilterData = () => {
        const { files, name_filter, mes_filter, ano_filter } = this.state;

        // Criamos um array de filtros dinâmico
        const filters = [
            { key: 'name', value: name_filter, match: (file, value) => file.name.toLowerCase().includes(value.toLowerCase()) },
            { key: 'mes', value: mes_filter, match: (file, value) => file.mes === value },
            { key: 'ano', value: ano_filter, match: (file, value) => file.ano === value },
        ];

        // Filtra os usuários com base nos filtros que foram preenchidos
        const filteredfiles = files.filter(file => {
            return filters.every(filter => {
                // Só aplicamos o filtro se o valor estiver preenchido
                return !filter.value || filter.match(file, filter.value);
            });
        });
        console.log(filteredfiles)
        // Atualiza o estado com os usuários filtrados
        this.setState({ filters: filteredfiles });
    };

    handleYearsForFilter = () => {
        const { files } = this.state;
        const years = [];
        files.forEach((file) => {
            const is_reg = years.find((y) => y === file.ano);
            if (!is_reg) {
                years.push(file.ano);
            }
        });
        this.setState({
            years: years
        })
    };


    render() {
        const { files, pdf, fileId, assinado, loading, years, filters, urlPdf } = this.state;
        return (
            <>
                {pdf && <PdfView urlPdf={urlPdf} pdfBlob={pdf} fechar={() => { this.setState({ pdf: '' }); this.handleGetFiles(); }} fileId={fileId} assinado={assinado} />}
                {files.length ? (
                    <TableContainer>
                        <CadastroContainer>
                            {/* <Input placeholder='Buscar...' /> */}
                        </CadastroContainer>
                        <Table>
                            <TableHead>
                                <TableRowHeader>
                                    <TableHeader >ID</TableHeader>
                                    <TableHeader className='name-table'>Nome
                                        <input style={{ width: '150px' }} type='text' placeholder='...' onChange={(e) => this.handleFilter('name', e.target.value)} />
                                    </TableHeader>
                                    <TableHeader>Mês
                                        <select style={{ width: '80px' }} onChange={(e) => this.handleFilter('mes', e.target.value)} >
                                            <option>...</option>
                                            <option value={1}>Janeiro</option>
                                            <option value={2}>Fevereiro</option>
                                            <option value={3}>Março</option>
                                            <option value={4}>Abril</option>
                                            <option value={5}>Maio</option>
                                            <option value={6}>Junho</option>
                                            <option value={7}>Julho</option>
                                            <option value={8}>Agosto</option>
                                            <option value={9}>Setembro</option>
                                            <option value={10}>Outubro</option>
                                            <option value={11}>Novembro</option>
                                            <option value={12}>Dezembro</option>
                                        </select>
                                    </TableHeader>
                                    <TableHeader>Ano
                                        <select style={{ width: '80px' }} onChange={(e) => this.handleFilter('ano', e.target.value)} >
                                            <option>...</option>
                                            {years.map((y) => (
                                                <option value={y}>{y}</option>
                                            ))}
                                        </select></TableHeader>
                                    <TableHeader>Assinado</TableHeader>
                                    <TableHeader>Download</TableHeader>
                                </TableRowHeader>
                            </TableHead>
                            <TableBody>
                                {!filters.length ? files.map((file, index) => (
                                    <TableRow key={file.id} onClick={() => this.handleDownloadPDF(file.id, index)}>
                                        <TableData>{file.id}</TableData>
                                        <TableData className='name-table'>{file.name}</TableData>
                                        <TableData style={{
                                            textTransform: 'capitalize'
                                        }}>{this.handleMont(Number(file.mes))}</TableData>
                                        <TableData>{file.ano}</TableData>
                                        <TableData>
                                            <div className='status'>
                                                {file.assinado ? <MdCheck size={25} color='green' /> : <MdClose size={25} color='red' />}</div>
                                        </TableData>
                                        <TableData>
                                            <button className='btn-download' type='button' onClick={() => this.handleDownload(file.id, file.name)}><MdDownload size={25} /></button>
                                        </TableData>
                                    </TableRow>
                                )) : ''}

                                {filters.length ? filters.map((file, index) => (
                                    <TableRow key={file.id} onClick={() => this.handleDownloadPDF(file.id, index)}>
                                        <TableData>{file.id}</TableData>
                                        <TableData className='name-table'>{file.name}</TableData>
                                        <TableData style={{
                                            textTransform: 'capitalize'
                                        }}>{this.handleMont(Number(file.mes))}</TableData>
                                        <TableData>{file.ano}</TableData>
                                        <TableData>
                                            <div className='status'>
                                                {file.assinado ? <MdCheck size={25} color='green' /> : <MdClose size={25} color='red' />}</div>
                                        </TableData>
                                        <TableData>
                                            <button className='btn-download' type='button' onClick={() => this.handleDownload(file.id, file.name)}><MdDownload size={25} /></button>
                                        </TableData>
                                    </TableRow>
                                )) : ''}

                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <NoReg>
                        {loading ? (
                            <BarLoader />
                        ) : (
                            <h3>Sem arquivos no momento!</h3>
                        )}
                    </NoReg>
                )}
            </>
        );
    }
}

export default HoleritesUser;
