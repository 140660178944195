import styled from 'styled-components';
// Estilização básica usando styled-components
export const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

export const TabContainer = styled.div`
  /* display: flex; */
  justify-content: space-around;
  background-color: #a0326f;
  /* padding: 1rem 0; */
`;

export const TabButton = styled.button`
  background-color: ${(props) => (props.active ? '#7e234f' : '#a0326f')};
  color: white;
  border: none;
  padding: 1rem 2rem;
  cursor: pointer;
  font-size: 1rem;
  flex: 1;
  transition: background-color 0.3s;

  &:hover {
    background-color: #7e234f;
  }
  @media only screen and (max-width: 600px) {
    font-size: 11.6px;
  }
`;

export const ContentContainer = styled.div`
  flex: 1;
  /* padding: 2rem; */
  background-color: #f0f0f0;
`;